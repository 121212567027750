const middleware = {}

middleware['auth'] = require('../middleware/auth.ts')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['automationsRoutes'] = require('../middleware/automationsRoutes.js')
middleware['automationsRoutes'] = middleware['automationsRoutes'].default || middleware['automationsRoutes']

middleware['ensureCameraRoute'] = require('../middleware/ensureCameraRoute.js')
middleware['ensureCameraRoute'] = middleware['ensureCameraRoute'].default || middleware['ensureCameraRoute']

middleware['ensureUserProjectsAndCameras'] = require('../middleware/ensureUserProjectsAndCameras.ts')
middleware['ensureUserProjectsAndCameras'] = middleware['ensureUserProjectsAndCameras'].default || middleware['ensureUserProjectsAndCameras']

middleware['infoPagesRedirect'] = require('../middleware/infoPagesRedirect.js')
middleware['infoPagesRedirect'] = middleware['infoPagesRedirect'].default || middleware['infoPagesRedirect']

middleware['mediaHubRoutes'] = require('../middleware/mediaHubRoutes.ts')
middleware['mediaHubRoutes'] = middleware['mediaHubRoutes'].default || middleware['mediaHubRoutes']

middleware['projectsRedirect'] = require('../middleware/projectsRedirect.js')
middleware['projectsRedirect'] = middleware['projectsRedirect'].default || middleware['projectsRedirect']

middleware['unauth'] = require('../middleware/unauth.js')
middleware['unauth'] = middleware['unauth'].default || middleware['unauth']

middleware['unitSystemLookup'] = require('../middleware/unitSystemLookup.js')
middleware['unitSystemLookup'] = middleware['unitSystemLookup'].default || middleware['unitSystemLookup']

export default middleware
