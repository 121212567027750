export const BimInitialUploadLayout = () => import('../../components/storyblok/BimInitialUploadLayout.vue' /* webpackChunkName: "components/bim-initial-upload-layout" */).then(c => wrapFunctional(c.default || c))
export const CardBlok = () => import('../../components/storyblok/CardBlok.vue' /* webpackChunkName: "components/card-blok" */).then(c => wrapFunctional(c.default || c))
export const DroneInitialUploadLayout = () => import('../../components/storyblok/DroneInitialUploadLayout.vue' /* webpackChunkName: "components/drone-initial-upload-layout" */).then(c => wrapFunctional(c.default || c))
export const GridBlok = () => import('../../components/storyblok/GridBlok.vue' /* webpackChunkName: "components/grid-blok" */).then(c => wrapFunctional(c.default || c))
export const InfoCard = () => import('../../components/storyblok/InfoCard.vue' /* webpackChunkName: "components/info-card" */).then(c => wrapFunctional(c.default || c))
export const InfoImage = () => import('../../components/storyblok/InfoImage.vue' /* webpackChunkName: "components/info-image" */).then(c => wrapFunctional(c.default || c))
export const InfoPage = () => import('../../components/storyblok/InfoPage.vue' /* webpackChunkName: "components/info-page" */).then(c => wrapFunctional(c.default || c))
export const InfoSection = () => import('../../components/storyblok/InfoSection.vue' /* webpackChunkName: "components/info-section" */).then(c => wrapFunctional(c.default || c))
export const InfoVideo = () => import('../../components/storyblok/InfoVideo.vue' /* webpackChunkName: "components/info-video" */).then(c => wrapFunctional(c.default || c))
export const TutorialPage = () => import('../../components/storyblok/TutorialPage.vue' /* webpackChunkName: "components/tutorial-page" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
